<template>
  <div class="container full-bleed" style="background-color: #f6f6f4">
    <nav class="px-0">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <img
            alt="BonusLogs logo"
            src="./assets/BonusLogs.svg"
            style="height: 35px; width: auto"
          />
        </div>
        <div v-if="false">
          <button class="btn btn-warning font-weight-bold">Sign In</button>
        </div>
      </div>
    </nav>
    <!-- <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link> -->
    <router-view />
  </div>
  <!-- Footer -->
  <div class="container">
    <div class="my-4">
      <div
        class="d-flex flex-column flex-md-row align-items-center justify-content-between mb-2"
      >
        <div class="text-muted order-3 order-md-1 mb-2 mb-md-0">
          Copyright © 2023 - All rights reserved
        </div>
        <div class="text-center order-1 order-md-2 mb-4 mb-md-0">
          <img
            alt="BonusLogs logo"
            src="./assets/BonusLogs.svg"
            style="height: 30px; width: auto"
          />
        </div>
        <div class="text-muted mb-2 order-2 order-md-3 mb-md-0">
          Terms of Serice | Privacy Policy
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.full-bleed {
  box-shadow: 0 0 0 100vmax #f6f6f4;
  clip-path: inset(0 -100vmax);
}
</style>
