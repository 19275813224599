<template>
  <div class="container">
    <div class="row d-flex align-items-center" style="min-height: 500px">
      <div class="col-12 col-md-6 text-left px-0">
        <h1 class="font-weight-bold mt-5 mt-md-0">
          Who knew tracking hours could be fun?
        </h1>
        <div class="h5">A gamified time tracker for all your projects.</div>
        <div
          v-if="false"
          class="d-flex flex-column align-items-center float-left"
        >
          <button class="hero-btn mt-5">Start Tracking Time</button>
          <div class="font-weight-bold mt-3">(Did I mention it’s free?)</div>
        </div>
        <!-- Email CTA Mobile -->
        <div v-if="!isEmailSuccess" class="d-xl-none" style="min-height: 175px">
          <form
            class="mt-4 d-flex flex-column"
            style="min-height: 120px"
            name="email"
            @submit.prevent="submitEmail()"
          >
            <div>
              <input
                class="email-collection w-100"
                type="email"
                name="email"
                placeholder="Enter your email"
                v-model="email"
              />
            </div>
            <div class="mt-2">
              <button class="email-button w-100">Get Early Access</button>
            </div>
          </form>
          <div v-if="emailError" class="alert alert-danger d-xl-none mt-4">
            {{ emailError }}
          </div>
        </div>
        <Transition>
          <div
            v-if="isEmailSuccess"
            class="d-xl-none"
            style="min-height: 175px"
          >
            <div
              class="shadow-sm cta-success p-3 mt-4 d-flex"
              style="min-height: 120px"
            >
              <div>
                <img
                  class="img-fluid mt-md-0 mr-4"
                  src="../assets/check.svg"
                  style="height: 55px; width: auto; margin-top: -15px"
                />
              </div>
              <div class="px-2">
                <p class="font-weight-bold mb-0 small">Woohoo!</p>
                <p class="mb-0 mt-0 small">
                  You've secured your spot for early access! Keep an eye on your
                  inbox - exciting updates and important details are headed your
                  way.
                </p>
              </div>
            </div>
          </div>
        </Transition>
        <!-- Email CTA Desktop -->
        <div class="d-none d-xl-block" style="min-height: 175px">
          <form
            v-if="!isEmailSuccess"
            class="mt-0 align-items-center d-xl-flex"
            style="min-height: 105px"
            name="email"
          >
            <div>
              <input
                class="email-collection"
                type="email"
                placeholder="Enter your email"
                v-model="email"
                style="width: 376px"
                name="email"
              />
            </div>
            <div>
              <button @click.prevent="submitEmail()" class="email-button">
                Get Early Access
              </button>
            </div>
          </form>
          <div v-if="emailError" class="alert alert-danger d-none d-xl-flex">
            {{ emailError }}
          </div>

          <Transition>
            <div
              v-if="isEmailSuccess"
              class="shadow-sm cta-success p-3 mt-5 d-none d-md-flex align-items-center"
            >
              <div>
                <img
                  class="img-fluid mt-md-0 mr-4"
                  src="../assets/check.svg"
                  style="height: 35px; width: auto"
                />
              </div>
              <div class="px-4">
                <p class="font-weight-bold mb-0">Woohoo!</p>
                <p class="mb-0 mt-0">
                  You've secured your spot for early access! Keep an eye on your
                  inbox - exciting updates and important details are headed your
                  way.
                </p>
              </div>
            </div>
          </Transition>
        </div>
      </div>
      <div class="col-12 col-md-6 text-center">
        <div>
          <img
            alt="Busy Beaver"
            src="../assets/Busy Beaver BonusLogs.png"
            class="img-fluid mt-3 mt-md-0"
          />
        </div>
      </div>

      <!-- <HelloWorld msg="Welcome to Your Vue.js + TypeScript App" /> -->
    </div>
    <div class="row d-flex align-items-center" style="min-height: 500px">
      <div class="col-12 col-md-6 text-left">
        <h2 class="font-weight-bold mt-5 mb-3">
          Track your time, level up, earn coins and get rewarded.
        </h2>
        <p class="mb-5">
          Let's be honest tracking project time is boring, especially when
          you're using spreadsheets to do it. Stay motivated to stay on track by
          earning rewards.
        </p>
      </div>
      <div class="col-12 col-md-6 px-0 mb-5">
        <img
          alt="BonusLogs Dashboard"
          src="../assets/Dashboard.png"
          class="rounded-sm shadow-sm img-fluid"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { defineComponent } from "vue";
import HelloWorld from "@/components/HelloWorld.vue";
import confetti from "canvas-confetti";
import { ref } from "vue";
import { supabase } from "@/views/supabase/init";

export default defineComponent({
  components: {},
  data() {
    return {
      isEmailSuccess: false as boolean,
      email: "" as string,
      emailError: "" as string,
    };
  },
  methods: {
    async submitEmail() {
      console.log("submitEmail called");
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (this.email.trim() === "") {
        this.emailError = "Email is required!";
        return;
      } else if (!emailRegex.test(this.email)) {
        this.emailError = "Invalid email address";
        return;
      } else {
        this.emailError = "";
      }

      try {
        const { error } = await this.$supabase
          .from("waitlist_form")
          .insert([{ email: this.email }]);

        if (error) {
          console.error("Error submitting email:", error.message);
          return;
        }

        console.log("Email submitted successfully!");

        // Additional logic for email success
        this.isEmailSuccess = true;
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { x: 0.5, y: 0.6 },
        });
      } catch (error) {
        console.error("Error:", error);
      }
    },
  },
});
</script>
<style>
.hero-btn {
  border-radius: 5px;
  border: none;
  background: #f9bd41;
  color: #000;
  font-weight: 600;
  width: 341px;
  height: 60px;
  padding: 10px;
}
.hero-btn:hover {
  background: #f9bc41bb;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.7s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.collections-box {
  border-radius: 5px;
  border: none;
  height: 269px;
  width: 335px;
  background: #fbfbfb;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.email-collection {
  border-radius: 5px 0px 0px 5px;
  min-height: 56px;
  border: 1px solid #c0c0c0;
  border-right: 0px;
  padding: 15px 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
@media (max-width: 991px) {
  .email-collection {
    border-radius: 5px;
    border: 1px solid #c0c0c0;
    padding: 10px 15px;
  }
}
.email-collection:focus {
  outline: none !important;
  border: 1px solid #000 !important;
}
.email-button {
  border-radius: 0px 5px 5px 0px;
  border: 1px solid #f9bd41;
  padding: 15px 20px;
  background: #f9bd41;
  font-weight: bold;
}
.email-button:hover {
  border-radius: 0px 5px 5px 0px;
  border: 1px solid #f9bc41c2;
  padding: 15px 20px;
  background: #f9bc41c2;
  font-weight: bold;
}
.cta-success {
  background: #fff;
  border: none;
  border-radius: 5px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
</style>
