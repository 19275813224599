import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { createClient } from "@supabase/supabase-js";
import { supabase } from "./views/supabase/init";

const supabaseUrl = process.env.VUE_APP_SUPABASE_URL as string;
const supabaseKey = process.env.VUE_APP_SUPABASE_ANON_KEY as string;
const client = createClient(supabaseUrl, supabaseKey);

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

const app = createApp(App);
app.config.globalProperties.$supabase = supabase;

app.use(store).use(router).mount("#app");
